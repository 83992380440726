import React, {useState, useEffect} from "react"
import countries from '../content/countries.json'
import langs from '../content/langs.json'

export default function Hello(){
    const [countryCode, setCountryCode] = useState(0)
    useEffect(() => {
        fetch("https://api.ipdata.co/?api-key=bd59a55b45569d76851a25b3f127946008c801fb21f5f5e0fbc2c133")
          .then(response => response.json()) // parse JSON from request
          .then(resultData => {
              if (resultData.country_code)
                setCountryCode(resultData.country_code)
                
            return
          })
    })

    if (countryCode.length !== 2) {
        setCountryCode('US')
    }

    var lang = 'en'
    var hello = 'Hello'

    if(countryCode)
        if (countries[countryCode].languages[0]) {
            lang = countries[countryCode].languages[0]
        } else {
            lang = 'en'
        }

        if (langs[lang])
            hello = langs[lang].hello

    return (
        <h1 className="text-pink tracking-tighter">{hello}</h1>
    )
}