import React from "react"
import Navbar from "../components/navbar"
import Hello from "../components/hello"
import SEO from "../components/SEO"
import { Link } from "gatsby"
import Image from "../components/image"

export default function Home() {
  return (
    <main>
      <SEO title="Product Designer and Entrepreneur" pathname="/" />

      <Navbar></Navbar>

      <div className="lg:flex lg:h-screen pt-16 lg:pt-0">
        <div className="p-6 lg:w-1/2 w-full self-center">
          <Hello></Hello>

          <p className="text-3xl font-semibold mb-3">This is Fahim Murshed.</p>

          <p className="text-2xl font-light mb-3">
            I'm a 22-year-old product designer, specialized in digital solutions. I can read and write code. I am passionate about building scalable digital solutions that can translate into great and useful user experiences. I am also enthusiastic about responsible AI and the future of the web.
          </p>

          <p className="text-2xl font-light mb-3">
            I co-founded and run <a href="https://symbl.co/" target="_blank" rel="noreferrer">Symbl</a>, a technology company based in Dhaka. I make premium courses for <a href="https://10minuteschool.com/" target="_blank" rel="noreferrer">10 Minute School</a>, the largest online education platform in Bangladesh. I also actively contribute and collaborate on a few <Link to="/pets">pet projects</Link>.
          </p>

          <p className="text-2xl font-light mb-3">
            Beside <a href="https://github.com/fahimmurshed14" target="_blank" rel="noreferrer">code</a> and <a href="https://www.linkedin.com/in/fahimmurshed/" target="_blank" rel="noreferrer">work</a>, I'm a film buff and a <a href="https://www.goodreads.com/user/show/45813473-fahim-murshed" target="_blank" rel="noreferrer">semi-bookworm</a>. Pink Floyd is my spiritual fuel. I'm an indoor cat. And, I love black and white a bit <a href="https://www.instagram.com/fahimmurshedo_o/" target="_blank" rel="noreferrer">too much</a>.
          </p>
        </div>
        <div className="lg:w-1/2 w-full self-end">
          <Image imgName="fahim-pixelated.jpg" />
        </div>
      </div>
    </main>
  )
}
